/* src/Privacy.js */
import React from "react";
import HeaderPost from "./components/header/HeaderPost";
import Footer from "./components/footer/Footer";
import PrivacyText from "./components/privacyText/PrivacyText";
import "./App.css";

const Privacy = () => {
  return (
    <div>
      <div className={"app"}>
        <div className="app-container">
          <HeaderPost showHordeByCountry={false}/>
          <Footer />
          <PrivacyText />
        </div>
      </div>
    </div>
  );
};

export default Privacy;
