/* src/controllers/countdown.js */
import { differenceInMilliseconds } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export function updateTimeRemaining() {
  const timeZone = "Europe/Paris";
  const startDate = utcToZonedTime(new Date(), timeZone);
  const endDate = utcToZonedTime(new Date("2023-10-31T00:00:00"), timeZone);
  const timeDiff = differenceInMilliseconds(endDate, startDate);

  return {
    days: Math.floor(timeDiff / (1000 * 60 * 60 * 24)),
    hours: Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((timeDiff % (1000 * 60)) / 1000),
  };
}

export function updateTimeElapsed() {
  const timeZone = "Europe/Paris";
  const currentDate = utcToZonedTime(new Date(), timeZone);
  const halloweenDate = utcToZonedTime(
    new Date("2023-10-31T00:00:00"),
    timeZone
  );
  const timeDiff = differenceInMilliseconds(currentDate, halloweenDate);

  return {
    days: Math.floor(timeDiff / (1000 * 60 * 60 * 24)),
    hours: Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((timeDiff % (1000 * 60)) / 1000),
  };
}
