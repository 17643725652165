/* src/component/loading/Loading.js */
import React, { useState, useEffect } from "react";
import "./Loading.css";

const Loading = ({ labelDelay, onLoadingFinished }) => {
  const labels = [
    "loading dna.world",
    "extracting z-end.mut",
    "building z-end.world",
  ];
  const [currentLabelIndex, setCurrentLabelIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress >= 100) {
      onLoadingFinished();
      return;
    }

    if (progress > 66) {
      setCurrentLabelIndex(2);
    } else if (progress > 33) {
      setCurrentLabelIndex(1);
    } else {
      setCurrentLabelIndex(0);
    }

    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress + 1) % 101);
    }, labelDelay);

    return () => clearInterval(timer);
  }, [progress, labelDelay, onLoadingFinished]);

  return (
    <div className="loading-container">
      <p className="loading-label">{labels[currentLabelIndex]}</p>
      <div className="loading-bar">
        <div
          className="loading-progress"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default Loading;
