/* src/component/gameRules/GameRules.js */
import React from "react";
import "./GameRules.css";

const GameRules = () => {
  return (
    <div className="gameRules">
      <div className="gameRules-title">GAME RULES</div>
      <ul className="gameRules-list">
        <li>Your goal is to be the best zombie.</li>
        <li>By infecting as many humans.</li>
        <li>Only infected humans can play.</li>
        <li>So you need to get infected first.</li>
        <li>Earn points each time you infect someone.</li>
        <li>Get more points by building the combo.</li>
        <li>Infected humans cannot be infected again.</li>
      </ul>
    </div>
  );
};

export default GameRules;
