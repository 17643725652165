/* src/AccountDelete.js */
import React from "react";
import HeaderPost from "./components/header/HeaderPost";
import Footer from "./components/footer/Footer";
import AccountDeleteForm from "./components/accountDeleteForm/AccountDeleteForm";
import "./App.css";

const AccountDelete = () => {
  return (
    <div>
      <div className={"app"}>
        <div className="account-delete-container">
          <HeaderPost showHordeByCountry={false}/>
          <Footer />
          <AccountDeleteForm />
        </div>
      </div>
    </div>
  );
};

export default AccountDelete;
