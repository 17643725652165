/* src/component/title/TitlePost.js */
import React, { useEffect, useState } from "react";
import "./Title.css";

function TitlePost(props) {
  const { initialInfectedCount } = props;
  const [infectedCount, setInfectedCount] = useState(initialInfectedCount);

  useEffect(() => {
    if (initialInfectedCount) {
      setInfectedCount(initialInfectedCount);
    }
  }, [initialInfectedCount]);
  return (
    <div className="title">
      <div className="title-label-post">Z-END HAS STARTED</div>
      <div className="label-counter-inline">
        Already {infectedCount} human
        {infectedCount && infectedCount > 1 ? "s" : ""} infected around the
        world
      </div>
      <div className="label-counter">
        Already {infectedCount} human
        {infectedCount && infectedCount > 1 ? "s" : ""} <br />
        infected around the world
      </div>
    </div>
  );
}

export default TitlePost;
