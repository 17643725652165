/* src/component/header/HeaderPre.js */
import React from "react";
import HordeByCountry from "../hordeByCountry/HordeByCountry";
import "./Header.css";
import { getAppLink } from "../../utils/Utils";

function HeaderPre(props) {
  const { showHordeByCountry } = props;

  return (
    <div className="header">
      <div className="header-container">
        <div className="header-left">
          {/* <a href={getAppLink()} className="header-logo-a">
            <img
              className="header-logo"
              src={process.env.PUBLIC_URL + "/logo192-black.png"}
              alt="logo"
            />
          </a>
          <a className="header-label" href={getAppLink()}>
            Download the app
          </a> */}
          <img
            className="header-logo"
            src={process.env.PUBLIC_URL + "/logo192-black.png"}
            alt="logo"
          />
          <div className="header-label" style={{ paddingLeft: 0 }}>
            App coming soon...
          </div>
        </div>
        <div className="header-right">
          <div className="header-right-container">
            <a href="https://twitter.com/z_end_world">
              <button className="header-button">
                <img
                  className="header-button-image"
                  src={process.env.PUBLIC_URL + "/images/twitter-64-black.png"}
                  alt="Twitter"
                />
              </button>
            </a>
            <a href="https://discord.gg/bS7p7d2p2M">
              <button className="header-button">
                <img
                  className="header-button-image"
                  src={process.env.PUBLIC_URL + "/images/discord-64-black.png"}
                  alt="Discord"
                />
              </button>
            </a>
            {showHordeByCountry && <HordeByCountry />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderPre;

