/* src/component/leaderboard/Leaderboard.js */
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { serverUrl } from "../../config";

import "./Leaderboard.css";
function Leaderboard(props) {
  const tableRef = useRef();
  const { initialLeaderboard, rank, page, zNumber } = props;
  const leaderboardLimit = 1000;
  const [data2, setData2] = useState(null);
  const [createdAt, setCreatedAt] = useState("");

  useEffect(() => {
    if (page && page > 0) {
      fetchData(page);
    }
  }, [page]);

  /*useEffect(() => {
    if (tableRef.current && rank) {
      const table = tableRef.current;
      const index = 10;//(rank % leaderboardLimit);
      console.log("index", index);
      const row = table.querySelector(`tr:nth-child(${index})`);
      console.log(row);
      if (row) {
        const offsetTop = row.offsetTop;
        table.scrollTop = offsetTop;
      }
    }
  }, [rank]);*/

  useEffect(() => {
    if (initialLeaderboard) {
      setData2(initialLeaderboard.scoreLeaderboard);
      setCurrentPage(initialLeaderboard.currentPage);
      setTotalPages(initialLeaderboard.totalPages);

      const toTimestamp = new Date(initialLeaderboard.createdAt).getTime();
      const formattedDate = new Date(toTimestamp).toLocaleString("en-US");
      setCreatedAt(formattedDate);
    } else {
      setData2([]);

    }
  }, [initialLeaderboard]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleFirstPage = async () => {
    await fetchData(1);
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      await fetchData(currentPage - 1);
    }
  };

  const handleNextPage = async () => {
    if (currentPage < totalPages) {
      await fetchData(currentPage + 1);
    }
  };

  const handleLastPage = async () => {
    await fetchData(totalPages);
  };

  const fetchData = async (page) => {
    try {
      const response = await axios.get(
        `${serverUrl}/leaderboard/latest?page=${page}`
      );

      const defaultData = {
        zNumber: -1,
        score: 0,
        count: 0,
        combo: 0,
        isHordeMember: 0,
        country_code: "??",
      };
      while (response.data.scoreLeaderboard.length < 1000) {
        response.data.scoreLeaderboard.push(defaultData);
      }
      setData2(response.data.scoreLeaderboard);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize.height, windowSize.width]);
  return (
    <div className="leaderboard-container">
      <div className="leaderboard-content">
        <div
          className="leaderboard-title"
          style={{ textAlign: "left", paddingLeft: "20px" }}
        >
          Z-NUMBER RANKING
        </div>
        <div className="leaderboard-title2" style={{ textAlign: "center" }}>
          <div className="leaderboard-footer">
            {initialLeaderboard && data2 && data2.length > 0 && (
              <div>At {createdAt}</div>
            )}
            {initialLeaderboard && data2 && data2.length > 0 && (
              <div>
                Rank {1 + (currentPage - 1) * leaderboardLimit} to{" "}
                {leaderboardLimit + (currentPage - 1) * leaderboardLimit}
              </div>
            )}
          </div>
        </div>

        {initialLeaderboard && data2 && data2.length > 0 && (
          <div className="leaderboard-control-container">
            {currentPage > 1 ? (
              <button className="leaderboard-button" onClick={handleFirstPage}>
                <img
                  className="leaderboard-button-image"
                  src={process.env.PUBLIC_URL + "/images/first-64-green.png"}
                  alt="First"
                />
              </button>
            ) : (
              <button
                disabled
                className="leaderboard-button"
                onClick={() => {}}
              >
                <img
                  className="leaderboard-button-image"
                  src={process.env.PUBLIC_URL + "/images/first-64-grey.png"}
                  alt="First"
                />
              </button>
            )}
            {currentPage > 1 ? (
              <button className="leaderboard-button" onClick={handlePrevPage}>
                <img
                  className="leaderboard-button-image"
                  src={process.env.PUBLIC_URL + "/images/prev-64-green.png"}
                  alt="First"
                />
              </button>
            ) : (
              <button
                disabled
                className="leaderboard-button"
                onClick={() => {}}
              >
                <img
                  className="leaderboard-button-image"
                  src={process.env.PUBLIC_URL + "/images/prev-64-grey.png"}
                  alt="First"
                />
              </button>
            )}
            <div className="leaderboard-page-text">
              {currentPage}/{totalPages}
            </div>
            {currentPage < totalPages ? (
              <button className="leaderboard-button" onClick={handleNextPage}>
                <img
                  className="leaderboard-button-image"
                  src={process.env.PUBLIC_URL + "/images/next-64-green.png"}
                  alt="First"
                />
              </button>
            ) : (
              <button
                disabled
                className="leaderboard-button"
                onClick={() => {}}
              >
                <img
                  className="leaderboard-button-image"
                  src={process.env.PUBLIC_URL + "/images/next-64-grey.png"}
                  alt="First"
                />
              </button>
            )}
            {currentPage < totalPages ? (
              <button className="leaderboard-button" onClick={handleLastPage}>
                <img
                  className="leaderboard-button-image"
                  src={process.env.PUBLIC_URL + "/images/last-64-green.png"}
                  alt="First"
                />
              </button>
            ) : (
              <button className="leaderboard-button" onClick={() => {}}>
                <img
                  className="leaderboard-button-image"
                  src={process.env.PUBLIC_URL + "/images/last-64-grey.png"}
                  alt="First"
                />
              </button>
            )}
          </div>
        )}
        <table className="leaderboard-table" ref={tableRef}>
          <thead className="leaderboard-thead">
            <tr>
              <th className="leaderboard-header-title" style={{ width: "30%" }}>
                Rank
              </th>
              <th className="leaderboard-header-title" style={{ width: "35%" }}>
                Z-Number
              </th>
              <th className="leaderboard-header-title" style={{ width: "25%" }}>
                Score
              </th>
              {/* <th className="leaderboard-header-title" style={{ width: "15%" }}>
              Count
            </th> */}
              {/* <th className="leaderboard-header-title">Combo</th> */}
              <th
                className="leaderboard-header-title-end"
                style={{ width: "10%" }}
              >
                From
              </th>
              {/* <th>Horde Member</th> */}
            </tr>
          </thead>
          <tbody className="leaderboard-tbody">
            {(initialLeaderboard === null || (data2 &&
              data2.length == 0)) &&
            <tr>
              <td
                className="leaderboard-cell-en-line-end"
                style={{
                  width: "100%",
                  height: "40px",
                }}
              >
                NO DATA
              </td>
            </tr>}
            {data2 &&
              data2.length > 0 &&
              data2.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor:
                      item.zNumber === zNumber ? "#32c456" : "#00000000",
                  }}
                >
                  <td
                    className={
                      index === data2.length - 1
                        ? "leaderboard-cell-line-end"
                        : "leaderboard-cell"
                    }
                    style={{
                      width: "30%",
                      color: item.isHordeMember
                        ? "#FF0000"
                        : item.zNumber === zNumber
                        ? "#000000"
                        : "#32c456",
                    }}
                  >
                    {item.zNumber != -1
                      ? index + 1 + leaderboardLimit * (currentPage - 1)
                      : "-"}
                  </td>
                  <td
                    className={
                      index === data2.length - 1
                        ? "leaderboard-cell-line-end"
                        : "leaderboard-cell"
                    }
                    style={{
                      width: "35%",
                      color: item.isHordeMember
                        ? "#FF0000"
                        : item.zNumber === zNumber
                        ? "#000000"
                        : "#32c456",
                    }}
                  >
                    {item.zNumber != -1
                      ? "#" + item.zNumber.toFixed(0).padStart(10, "0")
                      : "-"}
                  </td>
                  <td
                    className={
                      index === data2.length - 1
                        ? "leaderboard-cell-line-end"
                        : "leaderboard-cell"
                    }
                    style={{
                      width: "25%",
                      color: item.isHordeMember
                        ? "#FF0000"
                        : item.zNumber === zNumber
                        ? "#000000"
                        : "#32c456",
                    }}
                  >
                    {item.zNumber != -1 ? item.score : "-"}
                  </td>
                  {/* <td
                  className={
                    index === data2.length - 1
                      ? "leaderboard-cell-line-end"
                      : "leaderboard-cell"
                  }
                  style={{ width: "15%" }}
                >
                {item.zNumber != -1 ? item.count : "-"}
                </td> */}
                  {/* <td
                  className={
                    index === data2.length - 1
                      ? "leaderboard-cell-line-end"
                      : "leaderboard-cell"
                  }
                >
                  {item.zNumber != -1 ? "+" + item.combo : "-"}
                </td> */}
                  <td
                    className={
                      index === data2.length - 1
                        ? "leaderboard-cell-end-line-end"
                        : "leaderboard-cell-end"
                    }
                    style={{
                      width: "10%",
                      color: item.isHordeMember
                        ? "#FF0000"
                        : item.zNumber === zNumber
                        ? "#000000"
                        : "#32c456",
                    }}
                  >
                    {/* {item.zNumber != -1 ? item.country_code.toUpperCase() : "-"} */}
                    
                    {item.zNumber != -1 ? "??" : "-"}
                  </td>
                  {/* <td>{item.isHordeMember === 1 ? "Yes" : "No"}</td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Leaderboard;
