/* src/component/chart/ChartCounter.js */
import React, { useState } from "react";
import "./Chart.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function ChartCounter(props) {
  const { labels, datasets } = props;

  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 7,
          color: "#32c456",
          font: {
            family: "Monospace",
            size: 11,
          },
        },
        grid: {
          color: "#32c45644", // Couleur de la ligne de l'axe x
        },
      },
      y: {
        ticks: {
          color: "#32c456",
          font: {
            family: "Monospace",
            size: 11,
          },
        },
        grid: {
          color: "#32c45644", // Couleur de la ligne de l'axe x
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Désactiver l'affichage de la légende
      },
      title: {
        display: true, // Activer l'affichage du titre
        text: "Number of infected",
        color: "#32c456",
        font: {
          family: "Monospace", // Famille de police du titre
          size: 16, // Taille de police du titre
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "infected",
        data: datasets,
        borderColor: "#FFFF00",
        backgroundColor: "#000000",
        borderWidth: 1,
        borderDash: [3, 3],
        pointStyle: "circle",
        pointRadius: 2,
        pointBorderColor: "#FFFF00",
        pointBorderWidth: 1,
      },
    ],
  };
  return <Line options={options} data={data} />;
}

export default ChartCounter;
