/* src/component/privacyText/PrivacyText.js */
import React from "react";
import "./PrivacyText.css";

function PrivacyText() {
  return (
    <div className="title">
      <div className="title-label">Privacy policy</div>
      <div className="textPrivacy">
        Last Updated: <i>09/09/2023</i>
      </div>
      <br />
      <div className="textPrivacy">
        Welcome to Z-END mobile application (hereinafter
        referred to as "the Application").
        <br /><br />
        We place great importance on the protection of your privacy and personal
        data. This privacy policy is intended to inform you about how we
        collect, use, and share your data when you use the Application.
        <br />
        <br /> By using the Application, you agree to the practices described in
        this privacy policy.
      </div>
      <br />
      <div className="textPrivacyTitle">1. Data Collected</div>
      <br />
      <div className="textPrivacy">
        When you use the Application, we may collect the following categories of
        data:
      </div>
      <br />
      <div className="textPrivacySubTitle">1.1 Shared Data</div>
      <br />
      <div className="textPrivacyList">
        - <u>Approximate Location</u>: we collect your approximate location for
        the purpose of providing geolocation-related features.
      </div>
      <br />
      <div className="textPrivacyList">
        - <u>Exact Location</u>: we collect your exact location for the purpose
        of providing geolocation-related features.
      </div>
      <br />
      <div className="textPrivacySubTitle">1.2 Collected Data </div>
      <br />
      <div className="textPrivacyList">
        - <u>Email Address</u>: we collect your email address to allow you to
        create a user account, receive important notifications, and contact you
        if necessary. We may also use your email address for marketing or
        advertising purposes with your prior consent.
      </div>
      <br />
      <div className="textPrivacySubTitle">
        1.3 Data Potentially Shared with Other Companies or Organizations
      </div>
      <br />
      <div className="textPrivacy">
        We may share certain data with partners or third parties, but only for
        the purpose of improving our services or customizing your experience.
        This data will not be used for advertising purposes without your
        explicit consent.
      </div>
      <br />
      <div className="textPrivacyTitle">2. Use of Data </div>
      <br />
      <div className="textPrivacy">
        We use the data collected in the Application for the following purposes:
      </div>
      <br />
      <div className="textPrivacyList">
        - Providing, customizing, and enhancing the features of the Application.
      </div>
      <br />
      <div className="textPrivacyList">
        - Contacting you to inform you about updates, news, promotions related
        to the Application, or our future projects, with your consent.
      </div>
      <br />
      <div className="textPrivacyList">
        - Responding to your support and service requests.
      </div>
      <br />
      <div className="textPrivacyList">
        - Ensuring compliance with our terms of use.
      </div>
      <br />
      <div className="textPrivacyTitle">3. Data Security </div>
      <br />
      <div className="textPrivacy">
        We take appropriate security measures to protect your data against loss,
        unauthorized access, disclosure, alteration, or destruction. Your data
        is transferred via a secure connection (HTTPS) and is encrypted during
        transmission.
      </div>
      <br />
      <div className="textPrivacyTitle">4. Data Deletion </div>
      <br />
      <div className="textPrivacy">
        You have the right to request the deletion of your data at any time. You
        can submit a request to delete your account and associated data using
        the following link:
        <br />
        <br />
        <a href="https://www.z-end.world/account/delete">
          https://www.z-end.world/account/delete
        </a>
        <br />
        <br /> However, please note that some data may be retained in accordance
        with the law or for security purposes.
      </div>
      <br />
      <div className="textPrivacyTitle">5. Contact Us</div>
      <br />
      <div className="textPrivacy">
        If you have any questions regarding this privacy policy or wish to
        exercise your rights regarding your personal data, please contact us at
        the following email address:
        <br />
        <br />
        <b>
          <i>support@z-end.world</i>
        </b>
      </div>
      <br />
      <div className="textPrivacyTitle">6. Changes to the Privacy Policy</div>
      <br />
      <div className="textPrivacy">
        We reserve the right to modify this privacy policy at any time. Any
        changes will be published within the Application and will take effect
        immediately.
        <br />
        <br />
        We encourage you to regularly review this privacy policy to stay
        informed about our data protection practices.
        <br />
        <br />
        By using the Application after any modification of this policy, you
        accept the updated practices.
        <br />
        <br /> Thank you for using Z-END! Your privacy is important to us, and
        we are committed to protecting your personal data.
      </div>
      <br />
    </div>
  );
}

export default PrivacyText;
