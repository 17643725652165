/* src/component/zNumberForm/ZNumberForm.js */
import React, { useEffect, useState } from "react";
import "./ZNumberForm.css";

function ZNumberForm(props) {
  const { initialZNumber, onZNumberChanged, initialLoading } = props;
  const [zNumber, setZNumber] = useState(initialZNumber);
  const [loading, setLoading] = useState(initialLoading);

  const buttonClass = loading ? "inactive-button" : "active-button";
  useEffect(() => {
    if (initialZNumber) {
      setZNumber(initialZNumber);
    } else {
      setZNumber("");
    }
  }, [initialZNumber]);

  useEffect(() => {
    setLoading(initialLoading);
  }, [initialLoading]);

  const handleZNumberChange = (e) => {
    const inputValue = e.target.value;
    const maxDigits = 10;
    const maxNumber = 9999999999;
    const minNumber = 0;
    if (inputValue.length === 0) {
      setZNumber(e.target.value);
      return;
    }
    if (
      inputValue.length <= maxDigits &&
      parseInt(inputValue) <= maxNumber &&
      parseInt(inputValue) >= minNumber
    ) {
      setZNumber(e.target.value);
    }
  };

  const handleZNumber = async (e) => {
    e.preventDefault();
    onZNumberChanged(zNumber);
  };

  return (
    <div className="zNumberForm-form">
      <form onSubmit={handleZNumber}>
        <div className="zNumberForm-container">
          <label className="zNumberForm-form-title" htmlFor="email">
            🧟‍♂️ Enter your Z-Number 🧟‍♂️
          </label>
          <div className="zNumberForm-form2">
            <div className="sub-zNumberForm-form">
              <div className="label-zNumber">#</div>
              <input
                type="number"
                id="zNumber"
                placeholder="000000XXXX or XXXX"
                value={zNumber}
                onChange={handleZNumberChange}
              />
            </div>
            <button type="submit" className={buttonClass} disabled={loading}>
              Enter
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ZNumberForm;
