/* src/component/infectedCountByCountry/InfectedCountByCountry.js */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { serverUrl } from "../../config";

import "./InfectedCountByCountry.css";

function InfectedCountByCountry() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${serverUrl}/countries`);
        setData(response.data.country);
      } catch (error) {
        setData([]);
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize.height, windowSize.width]);
  return (
    <div className="infectedCountByCountry-container">
      <div className="infectedCountByCountry-content">
        <div
          className="infectedCountByCountry-title"
          style={{ textAlign: "left", paddingLeft: "20px" }}
        >
          COUNTRY RANKING
        </div>
        {/* <div className="infectedCountByCountry-title2" style={{ textAlign: "center" }}>
          <div className="infectedCountByCountry-footer">
            <div>at</div>
          </div>
        </div> */}
        <table className="infectedCountByCountry-table">
          <thead className="infectedCountByCountry-thead">
            <tr>
              {/* <th className="leaderboard-header-title">Rank</th> */}
              <th
                className="infectedCountByCountry-header-title"
                style={{ width: "25%" }}
              >
                Rank
              </th>
              <th
                className="infectedCountByCountry-header-title"
                style={{ width: "25%" }}
              >
                Country
              </th>
              <th
                className="infectedCountByCountry-header-title-end"
                style={{ width: "50%" }}
              >
                Count
              </th>
            </tr>
          </thead>
          <tbody className="infectedCountByCountry-tbody">
            {data && data.length == 0 && (
              <tr>
                <td
                  className="leaderboard-cell-en-line-end"
                  style={{
                    width: "100%",
                    height: "40px",
                  }}
                >
                  NO DATA
                </td>
              </tr>
            )}
            {data &&
              data.map((item, index) => (
                <tr key={index}>
                  <td
                    className={
                      index === data.length - 1
                        ? "infectedCountByCountry-cell-line-end"
                        : "infectedCountByCountry-cell"
                    }
                    style={{ width: "25%" }}
                  >
                    {index + 1}
                  </td>
                  <td
                    className={
                      index === data.length - 1
                        ? "infectedCountByCountry-cell-line-end"
                        : "infectedCountByCountry-cell"
                    }
                    style={{ width: "25%" }}
                  >
                    {/* {item.code.toUpperCase()} */}
                    {item.code}
                  </td>
                  <td
                    className={
                      index === data.length - 1
                        ? "infectedCountByCountry-cell-end-line-end"
                        : "infectedCountByCountry-cell-end"
                    }
                    style={{ width: "50%" }}
                  >
                    {item.count}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InfectedCountByCountry;
