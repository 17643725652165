/* src/component/disclaimer/Disclaimer.js */
import React, { useState } from "react";
import "./Disclaimer.css";

const Disclaimer = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const togglePanel = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <button className="toggle-button" onClick={togglePanel}>
        <img
          className="toggle-button-image"
          src={process.env.PUBLIC_URL + "/images/info-64-green.png"}
          alt="Disclaimer"
        />
      </button>
      {isExpanded && (
        <div className="panel-text">
          <button className="close-button" onClick={togglePanel}>
            <img
              className="close-button-image"
              src={process.env.PUBLIC_URL + "/images/x-mark-64-green.png"}
              alt="Close"
            />
          </button>
          <p className="disclaimer-title">DISCLAIMER</p>
          <ul className="disclaimer-list">
            <li>
              We're huge fans of zombie films and this is a game, first and
              foremost.
            </li>
            <li>
              The story, all names, characters, and incidents portrayed in this
              game are fictitious. No identification with actual persons (living
              or deceased), places, buildings, and products is intended or
              should be inferred.
            </li>
            <li>You're NOT really infected.</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Disclaimer;
