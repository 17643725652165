/* src/component/overlayQrCode/OverlayQrCode.js */
import React from "react";
import "./OverlayQrCode.css";
import { getAppLink } from "../../utils/Utils";

const OverlayQrCode = () => {
  return (
    <div className="container">
    <a className="text-qrcode" href={getAppLink()}>
      <img
        src={process.env.PUBLIC_URL + "/images/qr-code-app-1000x1000.png"}
        alt="Download the app"
        className="fixed-image"
      />
      <div className="text-qrcode-container">
          Download the app
      </div>
        </a>
    </div>
  );
};

export default OverlayQrCode;
