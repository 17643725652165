/* src/component/footer/Footer.js */
import React from "react";
import "./Footer.css";
import Sound from "../sound/Sound";
import Disclaimer from "../disclaimer/Disclaimer";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <Disclaimer />
          <Sound />
        </div>
        <div className="footer-right">
          <div className="footer-label">
            Made with 🧟‍♂️ by <b>Kevin Pavy</b> & <b>Yoann Pavy</b>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
