/* src/component/rFactor/RFactor.js */
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./RFactor.css";
import { Tooltip } from "react-tooltip";
import { serverUrl } from "../../config";

function RFactor({ scrollToRFactorChart }) {
  const [r0, setR0] = useState(0.0);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [formattedDate, setFormattedDate] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${serverUrl}/rFactor/daily/latest`);
        setR0(response.data.latestRFactor.value);
        setFrom(response.data.latestRFactor.from);
        setTo(response.data.latestRFactor.to);

        const toTimestamp = new Date(response.data.latestRFactor.to).getTime();
        const formattedDate = new Date(toTimestamp).toLocaleString("en-US");
        setFormattedDate(formattedDate);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="rFactor-panel-text">
      {/* <p className="rFactor-title">R Factor</p> */}
      <div className="rFactor-label" onClick={scrollToRFactorChart}>
        {r0.toFixed(1)}
        {/* ⓘ */}
      </div>
      <Tooltip
        anchorSelect=".rFactor-panel-text"
        place="right"
        style={{ color: "#32c456", fontSize: 12, textAlign: "left" }}
      >
        Virus reproduction rate
        <div
          style={{
            color: "#32c456",
            fontSize: 12,
            textAlign: "left",
          }}
        >
          <i>
            {/* {to.substring(0, 10)} at {to.substring(11).substring(0, 5)} */}
            {formattedDate}
          </i>
        </div>
      </Tooltip>
    </div>
  );
}

export default RFactor;
