/* src/AppPreHalloween.js */
import React, { useEffect, useState, useRef } from "react";
import Loading from "./components/loading/Loading";
import HeaderPost from "./components/header/HeaderPost";
import Footer from "./components/footer/Footer";
import TitlePost from "./components/title/TitlePost";
import ZNumberForm from "./components/zNumberForm/ZNumberForm";
import World from "./components/world/World";
import RFactor from "./components/rFactor/RFactor";
import GameRules from "./components/gameRules/GameRules";
import GameCombo from "./components/gameCombo/GameCombo";
import Leaderboard from "./components/leaderboard/Leaderboard";
import ChartRFactor from "./components/chart/ChartRFactor";
import ChartCounter from "./components/chart/ChartCounter";
import HowToPlay from "./components/howToPlay/HowToPlay";
import OverlayQrCode from "./components/overlayQrCode/OverlayQrCode";
import InfectedCountByCountry from "./components/infectedCountByCountry/InfectedCountByCountry";
import axios from "axios";
import { serverUrl } from "./config";
import { updateTimeElapsed } from "./controllers/countdown";

import "./components/title/Title.css";

const AppPost = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [zNumber, setZNumber] = useState("");
  const [infectedCount, setInfectedCount] = useState(0);
  const [infectionData, setInfectionData] = useState([]);
  const [filteredInfectionData, setFilteredInfectionData] =
    useState(infectionData);

  const tweet_msg =
    "Join the horde, infect the world this Halloween -> https://www.z-end.world. Z-END IS NEAR.";
  function handleLoadingFinished() {
    setIsLoading(false);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${serverUrl}/users/infected/count`);
        setInfectedCount(response.data.count);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 1000);

    return () => clearInterval(interval);
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [locationDate, setLocationDate] = useState("");
  const fetchData = async (page) => {
    setTimeout(async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/location/latest?page=${page}`
        );
        const locationDateTimestamp = new Date( response.data.createdAt).getTime();
        const locationDateFormattedDate = new Date(locationDateTimestamp).toLocaleString("en-US");
        setLocationDate(locationDateFormattedDate);
        const newData = response.data.location;
        if (newData.length > 0) {
          if (page === 1) {
            setInfectionData(newData);
          } else {
            setInfectionData((prevData) => [...prevData, ...newData]);
          }
          setCurrentPage(page + 1);
        } else {
          setLoading(false);
          setCurrentPage(1);
        }
      } catch (error) {
        setInfectionData([]);
        setLoading(false);
        setCurrentPage(1);
        console.error(error);
      }
    }, 100);
  };

  const loadAllData = async () => {
    if (loading) {
      await fetchData(currentPage);
    }
  };

  useEffect(() => {
    loadAllData();
  }, [loading, currentPage]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get("znumber");
    if (paramValue) {
      setZNumber(paramValue);
    } else {
      setZNumber("");
    }
  }, []);

  const handleZNumberChanged = (e) => {
    setZNumber(e);
  };

  const [leaderboardPage, setLeaderboardPage] = useState(1);
  const [leaderboardRank, setLeaderboardRank] = useState(null);
  useEffect(() => {
    const fetchData = async (zNumber) => {
      try {
        const response = await axios.get(
          `${serverUrl}/user/data/details?znumber=${zNumber}`
        );
        setFilteredInfectionData(response.data.details);
        setLeaderboardPage(response.data.rankPage);
        setLeaderboardRank(response.data.rank);
      } catch (error) {
        setLeaderboardPage(1);
        setLeaderboardRank(null);
        console.error(error);
      }
    };
    const intZNumber = parseInt(zNumber, 10);
    if(isNaN(intZNumber)) {
      setLeaderboardPage(1);
      setLeaderboardRank(null);
      setFilteredInfectionData(infectionData);

    } else if (intZNumber >= 0) {
      fetchData(intZNumber);
    } else {
      setLeaderboardPage(1);
      setLeaderboardRank(null);
      setFilteredInfectionData(infectionData);
    }
  }, [infectionData, zNumber]);

  // const [timeElapsed, setTimeElapsed] = useState(updateTimeElapsed());

  // function timeElapsedToString() {
  //   let result = "";
  //   if (timeElapsed.days > 0) {
  //     result += `${timeElapsed.days < 10 ? "0" : ""}${timeElapsed.days}:`;
  //   }
  //   if (timeElapsed.hours > 0 || result !== "") {
  //     result += `${timeElapsed.hours < 10 ? "0" : ""}${timeElapsed.hours}:`;
  //   }
  //   if (timeElapsed.minutes > 0 || result !== "") {
  //     result += `${timeElapsed.minutes < 10 ? "0" : ""}${timeElapsed.minutes}:`;
  //   }
  //   if (timeElapsed.seconds > 0 || result !== "") {
  //     result += `${timeElapsed.seconds < 10 ? "0" : ""}${timeElapsed.seconds}`;
  //   }
  //   return result;
  // }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimeElapsed(updateTimeElapsed());
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const [labels, setLabels] = useState([]);
  const [datasetsRFactor, setDatasetsRFactor] = useState([]);
  const [datasetsCounter, setDatasetsCounter] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${serverUrl}/rfactor/daily`);
        const labelsMap = response.data.rFactors.map((rFactor) => {
          const toTimestamp = new Date(rFactor.to).getTime();
          const formattedDate = new Date(toTimestamp).toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
          });
          return formattedDate;
        });
        const rFactorMap = response.data.rFactors.map((rFactor) => {
          return rFactor.value;
        });
        const counterMap = response.data.rFactors.map((rFactor) => {
          return rFactor.total;
        });
        setLabels(labelsMap);
        setDatasetsRFactor(rFactorMap);
        setDatasetsCounter(counterMap);
      } catch (error) {
        setLabels([]);
        setDatasetsRFactor([]);
        setDatasetsCounter([]);
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const [leaderboard, setLeaderboard] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${serverUrl}/leaderboard/latest`);

        const defaultData = {
          zNumber: -1,
          score: 0,
          count: 0,
          combo: 0,
          isHordeMember: 0,
          country_code: "??",
        };
        while (response.data.scoreLeaderboard.length < 1000) {
          response.data.scoreLeaderboard.push(defaultData);
        }
        setLeaderboard(response.data);
      } catch (error) {
        setLeaderboard(null);
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const divHowToStartRef = useRef();
  const divRFactorCharRef = useRef();
  const divRFactorCharRef2 = useRef();

  const scrollToHowToStart = () => {
    scrollTo(divHowToStartRef.current.offsetTop - 30);
  };

  const scrollToRFactorChart = () => {
    if (labels.length > 0) {
      const computedStyle = window.getComputedStyle(divRFactorCharRef.current);
      if (computedStyle.display !== "none") {
        scrollTo(divRFactorCharRef.current.offsetTop - 100);
      } else {
        scrollTo(divRFactorCharRef2.current.offsetTop - 270);
      }
    }
  };

  const scrollTo = (divOffset) => {
    window.scrollTo({
      top: divOffset,
      behavior: "smooth",
    });
  };

  return (
    <div style={{ backgroundColor: "black" }}>
      <div
        style={{ backgroundColor: "black" }}
        className={isLoading ? "app-loading" : "app"}
      >
        {isLoading && (
          <Loading labelDelay={20} onLoadingFinished={handleLoadingFinished} />
        )}
        <div
          className="app-container"
          style={{ display: isLoading ? "none" : "flex" }}
        >
          <HeaderPost />
          <Footer />
          <RFactor scrollToRFactorChart={scrollToRFactorChart} />
          <TitlePost initialInfectedCount={infectedCount} />
          {/* <div className="title-timer-elapsed">
            (since {timeElapsedToString()})
          </div> */}
          <button onClick={scrollToHowToStart} className="howToStart-button">
            HOW TO START
          </button>
          <ZNumberForm
            initialZNumber={zNumber}
            onZNumberChanged={handleZNumberChanged}
            initialLoading={loading}
          />
          <div className="panel-world-container">
            <div className="left-world-container">
              <div className="left-world-content">
                <Leaderboard
                  initialLeaderboard={leaderboard}
                  zNumber={parseInt(zNumber, 10)}
                  page={leaderboardPage}
                  rank={leaderboardRank}
                />
                <div className="left-world-content-country">
                  <InfectedCountByCountry />
                </div>
              </div>
            </div>
            <World
              initialZNumber={zNumber}
              initialInfectionData={filteredInfectionData}
              all={infectedCount}
              loading={loading}
              locationDate={locationDate}
            />
            <div className="right-world-container">
              <div className="right-world-content">
                {labels.length > 0 && (
                  <>
                    <div
                      ref={divRFactorCharRef2}
                      className="right-world-chart"
                      style={{ marginBottom: "20px" }}
                    >
                      <ChartRFactor
                        labels={labels}
                        datasets={datasetsRFactor}
                      />
                    </div>
                    <div className="right-world-chart">
                      <ChartCounter
                        labels={labels}
                        datasets={datasetsCounter}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* <div className="app-panel-world-container">
            <div className="left-panel-world-container">
              <Leaderboard initialLeaderboard={leaderboard} zNumber={zNumber} />
              <InfectedCountByCountry />
            </div>
            <World
              initialZNumber={zNumber}
              initialInfectionData={filteredInfectionData}
              all={infectedCount}
              loading={loading}
            />
            <div className="right-panel-world-container">
              <div className="chart-container" style={{ marginBottom: "10px" }}>
                <ChartRFactor labels={labels} datasets={datasetsRFactor} />
              </div>
              <div className="chart-container">
                <ChartCounter labels={labels} datasets={datasetsCounter} />
              </div>
            </div>
          </div> */}
          <a
            className="twitter-share-button"
            href={"https://twitter.com/intent/tweet?text=" + tweet_msg}
          >
            Share it to spread it
          </a>
          {/* <div className="app-panel-container">
            <Leaderboard initialLeaderboard={leaderboard} zNumber={zNumber} />
            <InfectedCountByCountry />
          </div> */}
          <div className="bottom-panel-world-container">
            <div className="app-tables-container">
              <div
                className="app-tables-left-content"
                style={{ marginBottom: "20px" }}
              >
                <Leaderboard
                  initialLeaderboard={leaderboard}
                  zNumber={parseInt(zNumber, 10)}
                  page={leaderboardPage}
                  rank={leaderboardRank}
                />
              </div>
              <div className="app-tables-right-content">
                <InfectedCountByCountry />
              </div>
            </div>
          </div>
          {labels.length > 0 && (
            <div
              ref={divRFactorCharRef}
              className="bottom-panel-world-container"
            >
              <div
                className="app-chart-container"
                style={{ marginBottom: "30px" }}
              >
                <div className="chart-container-left">
                  <ChartRFactor labels={labels} datasets={datasetsRFactor} />
                </div>
                <div className="chart-container">
                  <ChartCounter labels={labels} datasets={datasetsCounter} />
                </div>
              </div>
            </div>
          )}
          {/* <World
            initialZNumber={zNumber}
            initialInfectionData={filteredInfectionData}
            all={infectedCount}
            loading={loading}
          /> */}
          {/* <div
            className="app-panel-world-container"
            style={{ width: "100vw", alignContent: "center", justifyContent: "center"}}
          >
            <Leaderboard initialLeaderboard={leaderboard} zNumber={zNumber} />
            <World
              initialZNumber={zNumber}
              initialInfectionData={filteredInfectionData}
              all={infectedCount}
            />
            <InfectedCountByCountry />
          </div> */}

          <HowToPlay divRef={divHowToStartRef} />
          <div className="app-panel-container" style={{ paddingLeft: "20px" }}>
            <GameRules />
            <GameCombo />
          </div>
        </div>
        <OverlayQrCode />
        {/* <Chart /> */}
      </div>
    </div>
  );
};

export default AppPost;
