/* src/component/sound/Sound.js */
import React, { useState } from "react";
import { Howl } from "howler";
import "./Sound.css";

const sound = new Howl({
  src: ["/assets/z-end.mp3"],
  autoplay: true,
  loop: true,
  volume: 0.5,
});

const Sound = () => {
  const [isPlaying, setIsPlaying] = useState(true);

  const toggleSound = () => {
    if (!isPlaying) {
      sound.play();
    } else {
      sound.pause();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <button className="sound-button" onClick={toggleSound}>
      {isPlaying ? (
        <img
          className="sound-button-image-active"
          src={process.env.PUBLIC_URL + "/images/play-64-green.png"}
          alt="Play"
        />
      ) : (
        <img
          className="sound-button-image-inactive"
          src={process.env.PUBLIC_URL + "/images/pause-64-green.png"}
          alt="Pause"
        />
      )}
    </button>
  );
};

export default Sound;
