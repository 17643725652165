/* src/component/accountDeleteForm/AccountDeleteForm.js */
import React from "react";
import "./AccountDeleteForm.css";


function AccountDeleteForm() {
  return (
    <div className="title">
      <div className="title-label">Delete account instruction</div>
      <div className="text">Send an email from your address used for Z-END to <b>account-delete@z-end.world</b><br/> with the subject "Delete Account", the content of the email can remain empty.</div>
    </div>
  );
}

export default AccountDeleteForm;
