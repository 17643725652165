/* src/component/header/HeaderPost.js */
import React, { useState, useEffect } from "react";
import "./Header.css";
import { getAppLink } from "../../utils/Utils";

function HeaderPost() {
  return (
    <div className="header">
      <div className="header-container">
        <div className="header-left">
          <a href={getAppLink()} className="header-logo-a">
            <img
              className="header-logo"
              src={process.env.PUBLIC_URL + "/logo192-black.png"}
              alt="Logo"
            />
          </a>
          <a className="header-label" href={getAppLink()}>
            Download the app
          </a>
        </div>
        <div className="header-right">
          <div className="header-right-container">
            {/* <button onClick={scrollToDiv}>How to start</button> */}
            <a href="https://twitter.com/z_end_world">
              <button className="header-button-x-twitter">
                <img
                  className="header-button-image-x-twitter"
                  src={
                    process.env.PUBLIC_URL + "/images/x-twitter-64-black.png"
                  }
                  alt="Twitter"
                />
              </button>
            </a>
            <a href="https://discord.gg/bS7p7d2p2M">
              <button className="header-button">
                <img
                  className="header-button-image"
                  src={process.env.PUBLIC_URL + "/images/discord-64-black.png"}
                  alt="Discord"
                />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderPost;
