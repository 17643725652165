/* src/component/howToPlay/HowToPlay.js */
import React from "react";
import "./HowToPlay.css";

const HowToPlay = ({ divRef }) => {
  return (
    <div id="howToStartDiv" ref={divRef}>
      <div className="howToPlay-title">HOW TO START</div>
      <div className="panel-howToPlay">
        <div className="panel-howToPlay-case">
          <div className="howToPlay-subtitle">1: Find an infected</div>
          <img
            src={process.env.PUBLIC_URL + "/images/howToPlay/11.png"}
            alt="1"
            className="panel-howToPlay-image"
          />
        </div>
        <div className="panel-howToPlay-case">
          <div className="howToPlay-subtitle">2: Scan their QR Code</div>
          <img
            src={process.env.PUBLIC_URL + "/images/howToPlay/2.png"}
            alt="2"
            className="panel-howToPlay-image"
          />
        </div>
        <div className="panel-howToPlay-case">
          <div className="howToPlay-subtitle">3: Unlock Z-END app</div>
          <img
            src={process.env.PUBLIC_URL + "/images/howToPlay/3.png"}
            alt="3"
            className="panel-howToPlay-image"
          />
        </div>
        <div className="panel-howToPlay-case">
          <div className="howToPlay-subtitle">4: You're infected*</div>
          <img
            src={process.env.PUBLIC_URL + "/images/howToPlay/42.png"}
            alt="4"
            className="panel-howToPlay-image"
          />
        </div>
        {/* <div className="panel-howToPlay-case">
          <div className="howToPlay-subtitle">4: Infect the world yourself</div>
          <img
            src={process.env.PUBLIC_URL + "/images/howToPlay/4.png"}
            alt="4"
            className="panel-howToPlay-image"
          />
        </div> */}
      </div>
      {/* <div className="howToPlay-title2">5: May the best zombie win</div> */}
      <div className="howToPlay-title2">
        *You can now infect with your own QR Code
      </div>
    </div>
  );
};

export default HowToPlay;
