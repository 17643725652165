/* src/component/hordeByCountry/HordeByCountry.js */
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./HordeByCountry.css";
import { serverUrl } from "../../config";

function HordeByCountry() {
  const [isExpanded, setIsExpanded] = useState(false);

  const togglePanel = () => {
    setIsExpanded(!isExpanded);
  };
  const [countryCodeCounts, setCountryCodeCounts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {;
        const response = await axios.get(`${serverUrl}/horde/country/count`);
        setCountryCodeCounts(response.data.by_country.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 5000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <button className="hordeByCountry-toggle-button" onClick={togglePanel}>
        <img
          className="hordeByCountry-toggle-button-image"
          src={process.env.PUBLIC_URL + "/images/globe-64-black.png"}
          alt="ByCountry"
        />
      </button>
      {isExpanded && (
        <div className="hordeByCountry-panel-text">
          <button className="hordeByCountry-close-button" onClick={togglePanel}>
            <img
              className="hordeByCountry-close-button-image"
              src={process.env.PUBLIC_URL + "/images/x-mark-64-green.png"}
              alt="Close"
            />
          </button>
          <p className="hordeByCountry-title">INFECTED BY COUNTRY:</p>
          <div className="table-container">
            <table className="custom-table">
              <thead>
                <tr>
                  <th style={{ borderRight: "1px dashed #32c456" }}>Code</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {countryCodeCounts.map((item, index) => (
                  <tr key={index}>
                    <td
                      className="centered-cell"
                      style={{ borderRight: "1px dashed #32c456" }}
                    >
                      {item._id}
                    </td>
                    <td className="centered-cell">{item.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default HordeByCountry;
