/* src/component/gameCombo/GameCombo.js */
import React from "react";
import "./GameCombo.css";

const GameCombo = () => {
  return (
    <div className="gameCombo">
      <div className="gameCombo-title">ABOUT COMBO</div>
      <ul className="gameCombo-list">
        <li>Your combo starts at 1.</li>
        <li>And increases by +1 with each infection.</li>
        <li>You have 24 hours to infect a new human.</li>
        <li>Or your combo fails and returns to 1.</li>
        <li>There is no limit to the combo multiplier.</li>
        <li>So go ahead. Infect the world.</li>
        <li>And climb the leaderboard!</li>
      </ul>
    </div>
  );
};

export default GameCombo;
