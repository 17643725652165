/* src/index.js */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppPre from "./AppPre";
import AppPost from "./AppPost";
import AccountDelete from "./AccountDelete";
import Privacy from "./Privacy";
import reportWebVitals from "./reportWebVitals";
import { updateTimeRemaining } from "./controllers/countdown";

const url = window.location.pathname;
const root = ReactDOM.createRoot(document.getElementById("root"));

const timeRemaining = updateTimeRemaining();

const zEndHasStarted =
  (timeRemaining.days <= 0 &&
    timeRemaining.hours <= 0 &&
    timeRemaining.minutes <= 0 &&
    timeRemaining.seconds <= 0) ||
  (isNaN(timeRemaining.days) &&
    isNaN(timeRemaining.hours) &&
    isNaN(timeRemaining.minutes) &&
    isNaN(timeRemaining.seconds));
root.render(
  url === "/account/delete" ? (
    <React.StrictMode>
      <AccountDelete />
    </React.StrictMode>
  ) : url === "/privacy" ? (
    <React.StrictMode>
      <Privacy />
    </React.StrictMode>
  ) : (
    <React.StrictMode>
    <AppPost />
    </React.StrictMode>
  )
);

reportWebVitals();

