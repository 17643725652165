
const userAgent = navigator.userAgent.toLowerCase();

const isWindows = userAgent.includes("win");
const isMacOS = userAgent.includes("mac");
const isAndroid = userAgent.includes("android");
const isIOS =
  userAgent.includes("iphone") ||
  userAgent.includes("ipad") ||
  userAgent.includes("ipod");

export const getAppLink = () => {
  if (isWindows) {
    return "https://play.google.com/store/apps/details?id=com.zend.app";
  } else if (isMacOS) {
    return "https://apps.apple.com/app/id6463314079";
  } else if (isAndroid || isIOS) {
    return "https://zend.page.link/open";
  } else {
    return "https://play.google.com/store/apps/details?id=com.zend.app";
  }
};
