/* src/component/world/World.js */
import React, { useState, useEffect, useRef } from "react";
import Globe from "react-globe.gl";
import data from "../../datasets/data";
import * as THREE from "three";
import "./World.css";

const World = (props) => {
  const { initialZNumber, initialInfectionData, all, loading, locationDate } =
    props;
  const countries = data;
  const [infectionData, setInfectionData] = useState([]);
  const [color, setColor] = useState("#FFFF00");
  const [zNumber, setZNumber] = useState(-1);
  const globe = useRef();

  const [isLoading, setIsLoading] = useState(loading);
  useEffect(() => {
    globe.current.controls().autoRotateSpeed = 0.5; //loading ? 3.0 : 0.5;
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setInfectionData(initialInfectionData);
    globe.current.hexBinPointsData = initialInfectionData;
  }, [initialInfectionData]);

  useEffect(() => {
    if (initialZNumber) {
      const newZNumber = parseInt(initialZNumber, 10);
      setZNumber(newZNumber);
      if (newZNumber === 0) {
        setColor("#FF0000");
      } else if (newZNumber > all) {
        setColor("#4287F5");
      } else {
        setColor("#FFFF00");
      }
    } else {
      setColor("#FFFF00");
      setZNumber(-1);
    }
  }, [initialZNumber, all]);

  useEffect(() => {
    globe.current.controls().autoRotate = true;
    globe.current.controls().autoRotateSpeed = 0.5; //isLoading ? 3.0 : 0.5;
    globe.current.controls().enableZoom = false;
    globe.current.controls().enableRotate = true;
    globe.current.pointOfView({ altitude: 1.7 }, 2500);
  }, []);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      if (windowSize.width < windowSize.height) {
        globe.current.width = windowSize.width;
        globe.current.height = windowSize.width;
      } else {
        globe.current.width = windowSize.height;
        globe.current.height = windowSize.height;
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize.height, windowSize.width]);

  const globeMaterial = new THREE.MeshPhongMaterial({
    color: 0x000000,
    shininess: 0,
  });

  function getColorByValue(value, maxValue) {
    value = Math.max(0, Math.min(value, maxValue));
    const hue = (1 - value / maxValue) * 60;
    const hslColor = `hsl(${hue}, 100%, 50%)`;
    return hslColor;
  }

  const maxSize = 10000;
  return (
    <div>
      {/* {zNumber && zNumber > 0 ? (
        <div className="title-globe" style={{ color: zNumberColor() }}>
          Infection data
          <br />
          (#{zNumber.toFixed(0).padStart(10, "0")})
        </div>
      ) : zNumber == 0 ? (
        <div className="title-globe" style={{ color: zNumberColor() }}>
          Infection data
          <br />
          (Patient-0)
        </div>
      ) : (
        <div className="title-globe" style={{ color: zNumberColor() }}>
          Infection data
          <br />
          (Global)
        </div>
      )} */}
      {zNumber && zNumber === -1 ? (
        <div className="date-globe">World data, {locationDate}</div>
      ) : zNumber > all ? (
        <div className="date-globe">
          Invalid Z-Number
        </div>
      ) : zNumber === 0 ? (
        <div className="date-globe">Patient zero data</div>
      ) : (
        <div className="date-globe">
          #{zNumber.toFixed(0).padStart(10, "0")} data
        </div>
      )}
      <Globe
        ref={globe}
        className="globe"
        waitForGlobeReady={true}
        globeMaterial={globeMaterial}
        showGlobe={true}
        atmosphereAltitude={0.13}
        backgroundColor="#000000"
        hexBinPointsData={infectionData}
        hexBinPointWeight={1}
        hexAltitude={(d) =>
          d.sumWeight > maxSize ? 0.1 : d.sumWeight * 1e-5 + 2e-3
        }
        hexBinResolution={3}
        atmosphereColor={color}
        hexTopColor={(d) => getColorByValue(d.sumWeight, maxSize)}
        hexSideColor={(d) => getColorByValue(d.sumWeight, maxSize)}
        hexBinMerge={true}
        hexPolygonsData={countries.features}
        hexPolygonResolution={3}
        hexPolygonMargin={0.3}
        hexPolygonColor={() => `#32c456`}
        width={
          windowSize.width < windowSize.height
            ? windowSize.width * 0.9
            : windowSize.width * 0.4
        }
        height={
          windowSize.width < windowSize.height
            ? windowSize.width * 0.9
            : windowSize.width * 0.4
        }
        enablePointerInteraction={false}
      />
    </div>
  );
};

export default World;
